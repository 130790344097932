/* src/components/Services/GolfCartDetailing.css */
.golf-cart-service {
  position: relative;
  background-image: url('/src/assets/servicesAssets/golf-carts-info.jpg'); /* Update the path to your hero image */
  background-size: contain; /* Ensure the image covers the entire section */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  width: 100%;
  height: 100vh; /* Full viewport height */
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .golf-cart-service {
    background-image: url('/src/assets/mobile/golf-carts-info-mobile.jpg');
  }
}
