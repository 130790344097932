.header {
  background: linear-gradient(to right, rgb(16, 3, 30), rgb(101, 0, 208));
  color: #ffffff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.logo {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding-left: 100px;
  height: 100px;
}

.logo img {
  display: block;
  height: 100px; /* Adjust the height as needed */
  width: auto;
}


nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  gap: 50px;
  padding-right: 100px;
  margin: 0;
}

.nav-link {
  color: #ffffff;
  text-decoration: none;
  font-size: 1.2em;
  transition: color 0.5s ease;
  position: relative;
}

.nav-link::before {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #fff, #ccc, #fff);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s;
}

.nav-link:hover::before {
  transform: scaleX(1);
  transform-origin: left;
}

.nav-link:hover {
  color: rgba(255, 174, 0, 0.873);
}

/* Hamburger menu */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  padding: 20px; /* Adjust padding as needed */
}

.hamburger div {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 4px 0;
  transition: all 0.3s ease;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }

  .logo img {
    max-height: 100px; /* Larger logo for mobile */
    padding-left: 0;
  }

  .logo {
    justify-content: center; /* Center logo on mobile */
    order: 2; /* Ensure logo is centered */
    padding-left: 0px;
  }

  .hamburger {
    display: flex;
    order: 1; /* Ensure hamburger menu is on the left */
  }

  nav {
    display: none;
    flex-direction: column;
    gap: 10px;
    background: linear-gradient(to right, rgb(16, 3, 30), rgb(101, 0, 208));
    width: 100%;
    position: absolute;
    top: 60px; /* Adjust based on header height */
    left: 0;
    padding: 10px 0;
  }

  nav ul {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding-right: 0;
  }

  .nav-link {
    font-size: 1.2em;
    padding: 10px 0;
  }

  .hamburger.open div:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }

  .hamburger.open div:nth-child(2) {
    opacity: 0;
  }

  .hamburger.open div:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
  }

  nav.open {
    display: flex;
  }
}
