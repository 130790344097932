.hero-about {
  position: relative;
  background-image: url('/src/assets/aboutHero.jpg'); /* Update the path to your hero image */
  background-size: contain; /* Ensure the image covers the entire section */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  width: 100%;
  height: 100vh; /* Full viewport height */
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .hero-about {
    background-image: url('/src/assets/mobile/about-mobile.jpg');
  }
}

