.contact-container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  margin-bottom: 100px;
}

.contact-container h1 {
  text-align: center;
  margin-bottom: 20px;
}

.contact-container form {
  display: flex;
  flex-direction: column;
}

.contact-container label {
  margin-bottom: 8px;
  font-weight: bold;
}

.contact-form select{
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  width: 100%;
  margin-bottom: 16px;
}

.contact-container input,
.contact-container textarea {
  margin-bottom: 16px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.contact-container input[type="submit"] {
  background-color: #3b00c5d3;
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 16px;
}

.contact-container input[type="submit"]:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.contact-container p {
  text-align: center;
  margin-top: 20px;
}
