/* Services.css */
.services-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 60px;
  justify-content: center;
  padding: 20px;
}

.service-card {
  text-decoration: none;
  color: inherit;
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 8px;
  overflow: hidden;
  width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.service-card:hover {
  transform: scale(1.05);
}

.service-card img {
  width: 100%;
  height: auto;
}

.service-title {
  padding: 10px;
  text-align: center;
  font-size: larger;
  font-weight: 600;
  background-color: #fff;
}

.hidden-seo {
  display: none; /* Hides the SEO content from users */
}

