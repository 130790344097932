/* Existing styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1A1B1D;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.main-content {
  flex: 1;
}

.footer {
  background: linear-gradient(to right, rgb(16, 3, 30), rgb(101, 0, 208));
  color: #ffffff;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-shrink: 0; /* Ensure footer does not shrink */
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.footer-links {
  list-style-type: none;
  padding: 0;
  display: flex;
  gap: 30px;
  margin: 0;
  flex-wrap: wrap;
}

.footer-link {
  color: #ffffff;
  text-decoration: none;
  font-size: 1.2em;
  transition: color 0.5s ease;
}

.footer-link:hover {
  color: rgba(255, 174, 0, 0.873);
}

.contact-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.social-media {
  display: flex;
  gap: 15px;
}

.social-link {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.5s ease;
}

.social-link:hover {
  color: rgba(255, 174, 0, 0.873);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-links {
    flex-direction: column;
    gap: 10px;
  }

  .footer-link {
    font-size: 1em;
  }

  .contact-info {
    margin-top: 20px;
  }

  .social-media {
    margin-top: 20px;
  }
}
