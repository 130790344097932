html, body {
  height: 100%;
  margin: 0;
}

/* Flexbox for the wrapper to ensure footer is pushed to the bottom */
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Main content should take the remaining space */
main {
  flex: 1;
}

.footer {
  background: linear-gradient(to right, rgb(16, 3, 30), rgb(101, 0, 208));
  color: #ffffff;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}


.footer-links {
  list-style-type: none;
  padding: 0;
  display: flex;
  gap: 30px;
  margin: 0;
  flex-wrap: wrap;
}

.footer-link {
  color: #ffffff;
  text-decoration: none;
  font-size: 1.2em;
  transition: color 0.5s ease;
}

.footer-link::before {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #fff, #ccc, #fff);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s;
}

.footer-link:hover::before {
  transform: scaleX(1);
  transform-origin: left;
}

.footer-link:hover {
  color: rgba(255, 174, 0, 0.873);
}

.contact-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.social-media {
  display: flex;
  gap: 15px;
}

.social-link {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.5s ease;
}

.social-link:hover {
  color: rgba(255, 174, 0, 0.873);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-links {
    flex-direction: column;
    gap: 10px;
  }

  .footer-link {
    font-size: 1em;
  }

  .contact-info {
    margin-top: 20px;
  }

  .social-media {
    margin-top: 20px;
  }
}
