/* Main container for images */
.image-container {
  display: flex;
  flex-direction: column; /* Stack images vertically */
}

.image-container .section {
  width: 100%;
  height: 700px; /* Adjust height as needed */
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

/* Hero section */
.hero {
  background-image: url('/src/assets/homepageHero.jpg'); /* Replace with your desktop hero image path */
}

/* Services sections */
.services1 {
  background-image: url('/src/assets/homeServices1.jpg'); /* Replace with your desktop services1 image path */
}

.services2 {
  background-image: url('/src/assets/homeServices2.jpg'); /* Replace with your desktop services2 image path */
}

/* Hide services cards by default */
.services-grid-main {
  display: none;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .hero {
    background-image: url('/src/assets/mobile/homepageHeroMobile.jpg'); /* Replace with your mobile hero image path */
    height: 50vh; /* Adjust height for mobile */
  }

  .services1,
  .services2 {
    display: none; /* Hide services1 and services2 on mobile */
  }

  .services-grid-main {
    display: flex; /* Show services grid only on mobile */
    flex-wrap: wrap;
    gap: 20px; /* Adjust gap between cards */
    justify-content: center;
    padding: 20px;
  }
}

/* Service cards styles for mobile */
.service-card-main {
  text-decoration: none;
  color: inherit;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  width: 100%; /* Adjust width to fit better on mobile */
  max-width: 200px; /* Set a maximum width for the cards */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.service-card-main:hover {
  transform: scale(1.05);
}

.service-card-main img {
  width: 100%;
  height: auto;
}

.service-title-main {
  padding: 10px;
  text-align: center;
  font-size: 1rem; /* Adjust font size */
  font-weight: 600;
  background-color: #fff;
}
